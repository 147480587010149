<script>
import CandidatureCard from '@/components/general/CandidatureCard'
import ModalMessages from '@/components/general/ModalMessages'
export default {
  components: { CandidatureCard, ModalMessages },
  name: 'FinishedCandidature',
  data () {
    return {
      company: { },
      position: { },
      showModal: false,
      getApplicationsFinished: [],
      isLoading: true
    }
  },
  methods: {
    handleShowModalMessages (item) {
      this.company = { name: item.company.name, image: item.company.logo }
      this.position = { name: item.title, id: item.id }
      this.showModal = !this.showModal
    }
  },
  created () {
    this.$store.dispatch('attemptGetOldPositions', this.selectedWorkspace.name.toLowerCase()).then((data) => {
      const positions = []
      for (const item of data) {
        positions.push(item.position)
      }
      this.getApplicationsFinished = positions.filter(d => d.company.name.toLowerCase() === this.selectedWorkspace.name.toLowerCase())
      this.isLoading = false
    })
  }
}
</script>
<template>
  <div class="finished-candidature--container">
    <p>{{ $t('internal.mobility:finished.candidature.description') }}</p>
    <div class="finished-candidature--list">
      <div class="skeleton-finished" v-if="isLoading">
        <v-skeleton-loader class="skeleton-card-finished max-width" height="80px" type="list-item-avatar-two-line" v-for="i in ['a', 'b', 'c']"
          :key="i" />
      </div>
      <candidature-card  v-else v-for="item in this.getApplicationsFinished" :key="item.id" @handleModalMessage="handleShowModalMessages(item)"
        :candidature="{
          position: {
            company: {
              logo: item.company.logo,
              name: item.company.name
            },
            title: item.title,
            status: item.status,
          },
          stepsLeft: 1,
        }"
        forceStatus="closed"
      ></candidature-card>
    </div>
    <modal-messages
      v-if="showModal"
      :readonly="true"
      :company=company
      :position=position
      @close="showModal = false"
    ></modal-messages>
  </div>
</template>
<style lang="scss">
.finished-candidature--container {
  .finished-candidature--list {
    .skeleton-finished {
      clear: both;
      display: flex;
      flex-direction: column;

      .skeleton-card-finished {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
