var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"finished-candidature--container"},[_c('p',[_vm._v(_vm._s(_vm.$t('internal.mobility:finished.candidature.description')))]),_c('div',{staticClass:"finished-candidature--list"},[(_vm.isLoading)?_c('div',{staticClass:"skeleton-finished"},_vm._l((['a', 'b', 'c']),function(i){return _c('v-skeleton-loader',{key:i,staticClass:"skeleton-card-finished max-width",attrs:{"height":"80px","type":"list-item-avatar-two-line"}})}),1):_vm._l((this.getApplicationsFinished),function(item){return _c('candidature-card',{key:item.id,attrs:{"candidature":{
        position: {
          company: {
            logo: item.company.logo,
            name: item.company.name
          },
          title: item.title,
          status: item.status,
        },
        stepsLeft: 1,
      },"forceStatus":"closed"},on:{"handleModalMessage":function($event){return _vm.handleShowModalMessages(item)}}})})],2),(_vm.showModal)?_c('modal-messages',{attrs:{"readonly":true,"company":_vm.company,"position":_vm.position},on:{"close":function($event){_vm.showModal = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }